body {
  font-weight: 400;
}
p {
  font-size: 16px !important;
}

/* .css-19midj6 {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block !important;
  border-radius: 10px;
  padding: 30px !important;
  text-align: center !important;
}
.css-19midj6 .css-17w9904-MuiTypography-root {
  color: black !important;
  font-size: 18px !important;
  margin-bottom: 20px !important;
}
.css-17w9904-MuiTypography-root {
  font-size: 15px !important;
}
.css-19midj6 button:first-of-type {
  margin-right: 15px;
} */
.MuiInputLabel-formControl {
  /* top: -80% !important; */
  font-size: 18px !important;
}

.css-sitws5-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 40px !important;
}
.MuiInputBase-input {
}
.MuiTabs-flexContainer {
  margin-bottom: 30px;
}

.MuiDataGrid-cellContent {
  font-size: 17px !important;
}
.login {
  height: 100vh;
  width: 100%;
}
.login__wrapper {
  /* position: relative; */
  height: 100vh;
  width: 100%;
}
.login__form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.MuiBox-root {
  /* display: flex; */
  /* flex-wrap: wrap; */
}
.MuiBox-root > div {
  /* width: 100% !important; */
}
/* .MuiBox-root .css-1j14dpz {
  display: flex;
  flex-wrap: wrap;
} */
form input,
form textarea {
  width: 100% !important;
  font-size: 16px !important;
  line-height: 26px !important;
}
form input::placeholder,
form textarea::placeholder {
  font-size: 18px !important;
}
form select::placeholder {
  font-size: 18px !important;
}
form input:focus {
  outline: 1 !important;
}
/* .css-1j14dpz > div {
  width: 100% !important;
}
.css-1grhalw-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
}
.css-b8ulao-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #3d4e77 !important;
}
.css-1grhalw-MuiButtonBase-root-MuiTab-root {
  font-size: 16px;
} */
.MuiTabs-flexContainer {
  margin-bottom: 15px !important;
}
/* .css-o8jfh7-MuiTabs-indicator {
  background-color: #fff !important;
} */
.MuiTabs-scroller {
  margin-bottom: 30px !important;
}
/* .css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 15px !important;
}
.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: 1 !important;
}*/
.css-iiujvs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.css-162ttkw-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}
.css-75gcxd-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #fff !important;
}
input:focus label.Mui-focused {
  /* color: #fff !important; */
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}
.pro-sidebar {
  position: fixed !important;
}
.content {
  margin-left: 270px !important;
  margin-bottom: 50px !important;
}
/* .css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-size: 16px;
}
.css-1mx81p6-MuiDataGrid-root .MuiDataGrid-cellContent {
  font-size: 15px;
}
.css-1grhalw-MuiButtonBase-root-MuiTab-root,
.css-b8ulao-MuiButtonBase-root-MuiTab-root {
  font-size: 15px !important;
}
.css-3lgpxb-MuiButtonBase-root-MuiButton-root,
.css-1lw178f-MuiButtonBase-root-MuiButton-root {
  margin-right: 10px !important;
} */
.pro-inner-item {
  color: #fff !important;
}
.MuiBox-root .css-1m3bo6 .css-poa6bm-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important;
}

/* .MuiBox-root .css-1j14dpz {
  margin-top: 30px !important;
} */
.pro-menu-item.active span {
  color: #6870fa !important;
}
.Mui-error {
  color: red;
}
.invalid-field {
  border: 1px solid red;
}

.card {
  padding: 10px;
  box-shadow: 0 0 5px #ffdfdf;
  border-radius: 5px;
  overflow: hidden;
}
.card .top {
  text-align: center;
}
.card p {
  font-weight: 700;
  color: #0086fe;
}
.card button {
  outline: 0;
  border: 0;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  padding: 8px 13px;
  width: 100%;
  background-color: #0086fe;
}
.card .drag-area {
  height: 150px;
  border-radius: 5px;
  border: 2px dashed #0086fe;
  color: #0086fe;
  background-color: #f4f3f9;
  display: flex;
  justify-self: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 10px;
}
.card .drag-area .visible {
  font-size: 18px;
}
.card .select {
  color: #5256ad;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.4s;
}
.card .select:hover {
  opacity: 0.6;
}
.card .container-images {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
}
.card .container-images .image {
  width: 75px;
  margin-right: 5px;
  height: 75px;
  position: relative;
  margin-bottom: 8px;
}
.card .container-images .image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.card .container-images .image span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}
.card input,
.card .drag-area .on-drop,
.card .drag-area.dragover .visible {
  display: none;
}
.delete {
  z-index: 999;
  color: #0086fe;
}
.table-subcategory {
  cursor: pointer;
  color: #70d8bd;
  text-decoration: underline;
}

.subcategory-cell {
  overflow: visible !important;
  max-height: max-content !important;
  height: max-content !important;
  font-size: 16px;
}

.MuiDataGrid-row {
  /* max-height: max-content !important; */
  /* height: max-content !important; */
  /* min-height: max-content !important; */
}
.name-column--cell,
.subcategory-cell {
  max-height: none !important;
  max-width: none !important;
  min-height: none !important;
}
.MuiDataGrid-columnHeader {
  height: 72px;
}
.MuiDataGrid-root {
  /* border: 1px solid #000 !important; */
}
.MuiDataGrid-cell {
  border-bottom: 1px solid #fff !important;
}
.MuiDataGrid-columnHeaders {
  border-bottom: 1px solid #fff !important;
}
.MuiDataGrid-footerContainer {
  border-top: 1px solid #fff !important;
}
.w-15 {
  width: 15% !important;
}

.ql-container {
  min-height: 200px;
}

/* .fr-element .fr-view p,
ul,
ol {
  margin: 10px 0 !important;
} */

.fr-view p,
.fr-view ul,
.fr-view ol {
  margin: 10px 0 !important;
}

.editor-wrapper {
  border: 1px solid #ccc; /* Граница */
  padding: 10px; /* Отступ */
  height: 300px; /* Высота */
}

.demo-editor {
  height: 100%; /* Заполнение всей высоты родительского контейнера */
}
